<template>
  <v-card>
    <v-card-title>
      Daftar FAQ
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer />
      <create-faq-component />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="datas"
      :search="search"
      mobile-breakpoint="0"
    >
      <template v-slot:item="row">
        <tr :key="row.item.faqId">
          <td>{{ row.item.faqId }}</td>
          <td>{{ row.item.faqTitle }}</td>
          <td>{{ row.item.faq }}</td>
          <td>{{ row.item.type }}</td>
          <td>{{ format_date(row.item.created_at) }}</td>
          <td>
            <v-btn
              elevation="2"
              color="#E52A34"
              class="w-100"
              style="color: white"
              @click="onDeleteFaq(row.item.faqId)"
              >Hapus</v-btn
            >
          </td>
        </tr>
      </template></v-data-table
    >
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CreateFaqComponent from "../components/CreateFaqComponent.vue";

export default {
  name: "Faq",

  components: { CreateFaqComponent },

  data() {
    return {
      search: "",
      headers: [
        { text: "FAQ Id", value: "faqId" },
        { text: "Title", value: "faqTitle" },
        { text: "FAQ", value: "faq" },
        { text: "Type", value: "type" },
        { text: "Dibuat", value: "created_at" },
        { text: "", value: "" },
      ],
      datas: [],
      btnLoadingDelete: false,
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    fetchFaq() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      axios
        .create({ withCredentials: false })
        .get(`${process.env.VUE_APP_API_URL}/v1/admin/faq`, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          this.loading = false;

          this.datas = response.data.result;

          // console.log(response);
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
          console.log(error);
        });
    },
    onDeleteFaq(id) {
      let doConfirm = window.confirm(`Sure to delete this?`);

      if (doConfirm) {
        this.btnLoadingDelete = true;
        let config = {};

        if (this.$store.getters.getToken) {
          config = {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          };
        }

        axios
          .create({ withCredentials: false })
          .delete(`${process.env.VUE_APP_API_URL}/v1/admin/faq/${id}/delete`, config)
          .then((response) => {
            // console.log(response.data.result);
            this.$toast.success(response.data.message);
            this.fetchFaq();
          })
          .catch((error) => {
            this.$toast.warning(error.response.data.message);
          });
      }
    },
  },

  mounted() {
    this.fetchFaq();
  },
};
</script>
