<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn-tournaments"
        v-bind="attrs"
        v-on="on"
        color="#f8b32b"
        style="color: white"
      >
        <v-icon>mdi-plus</v-icon>
        Buat FAQ
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Membuat FAQ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="formCreate" v-model="valid" lazy-validation>
        <v-card-text>
          <div class="d-flex flex-column" style="margin-top: 24px">
            <label class="form-title">Judul FAQ</label>
            <v-text-field
              hide-details
              label="Tuliskan Judul FAQ"
              name="faq"
              single-line
              outlined
              v-model="faqTitle"
              :rules="fieldRequired"
            ></v-text-field>

            <label class="form-title">Tipe FAQ</label>
            <v-text-field
              hide-details
              label="Tuliskan Tipe FAQ"
              name="type"
              single-line
              outlined
              v-model="type"
              :rules="fieldRequired"
            ></v-text-field>

            <label class="form-title">Isi FAQ </label>

            <v-textarea
              hide-details
              outlined
              name="faq"
              label="Tuliskan isi FAQ"
              single-line
              v-model="faq"
              :rules="fieldRequired"
            ></v-textarea>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="btn-posting"
            color="#f8b32b"
            style="
              color: white;
              padding: 14px;
              margin-bottom: 24px;
              margin-top: 24px;
            "
            @click="onSaveFaq"
            :loading="isLoading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateFaqComponent",
  components: {},
  data: () => ({
    valid: false,
    fieldRequired: [(v) => !!v || "This field is required"],
    value: "",
    hidden: false,
    url: "",
    faqTitle: "",
    type: "",
    faq: "",

    modal: false,
    dialog: false,
    isLoading: false,
  }),
  mounted() {
    this.fetchFaq();
  },
  methods: {
    async onSaveFaq() {
      if (this.$refs.formCreate.validate()) {
        this.isLoading = true;
        let config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };

        const form = new FormData();
        form.append("type", this.type);
        form.append("faqTitle", this.faqTitle);
        form.append("faq", this.faq);

        await axios
          .create({ withCredentials: false })
          .post(`${process.env.VUE_APP_API_URL}/v1/admin/faq/add`, form, config)
          .then((response) => {
            // console.log(response.data.result);
            this.isLoading = false;
            window.location.reload();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            this.isLoading = false;
            this.$toast.warning(error.response.data.message);
          });
      }
    },
  },
};
</script>

<style>
.form-title {
  font-size: 16px;
  font-weight: 400;
  color: #141414;
  margin-top: 24px;
  margin-bottom: 8px;
}
.btn-posting {
  background-color: #f8b32b;
}
</style>
